import React, { useEffect, useState } from 'react';
import { Row, Col, Card, CardBody, CardTitle, Input } from 'reactstrap';
import Swal from 'sweetalert2';
import { format } from 'date-fns';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { popAgent, popTrip, setAgentsData, setTripData } from 'store/auth/user_admin_data/actions';
import Loader from 'components/loader/Loader';
import { SomethingAlertFalse, SomethingAlertTrue } from 'store/components/actions';
import Alert from 'components/alert/Alert';
import DataTable from 'react-data-table-component';
import { deleteAgent, deleteTrip, getAgents, getPackage } from 'helpers/fakebackend_helper';

const TravelAgentsDetails = () => {
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(true);
  const [message, setMessage] = useState('Something went wrong');
  const [tempTrip, setTempTrip] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');

  const fetchData = async () => {
    try {
      let tripData = await getAgents();
      dispatch(setAgentsData(tripData));
      setTempTrip(tripData);
      setLoader(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoader(false);
    }
  };

  useEffect(() => {
    setLoader(true);
    fetchData();
  }, []);

  const handleChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleRemoveTrip = async (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          dispatch(popAgent(id));
          setTempTrip((tempTrip) => tempTrip.filter((trip) => trip.id !== id));
          let res = await deleteAgent(id);
          console.log('Trip deleted successfully', res);
        } catch (error) {
          setMessage(
            error.response.data.message ? error.response.data.message : 'Something went wrong'
          );
          dispatch(SomethingAlertTrue());
          setTimeout(() => {
            dispatch(SomethingAlertFalse());
            setMessage('Something went wrong');
          }, 2000);
        }
      } else {
        // Handle cancel action if needed
      }
    });
  };

  const generateActionButtons = (row) => (
    <div>
      <Link to={`/viewTravelAgent/${row.id}`}>
        <button className="btn btn-primary mx-2">
          <i className="ti-eye"></i>
        </button>
      </Link>
      <button className="btn btn-danger mx-2" onClick={() => handleRemoveTrip(row.id)}>
        <i className="ti-trash"></i>
      </button>
      <Link to={`/editTravelAgents/${row.id}`}>
        <button className="btn btn-info mx-2">
          <i className="ti-pencil-alt"></i>
        </button>
      </Link>
    </div>
  );

  const { travelAgentData } = useSelector((state) => state.TravelAgentReducers);

  const columns = [
    {
      name: 'sno',
      selector: (row) => row.sno,
      sortable: true,
    },
    {
      name: 'Agent Name',
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: 'Based On',
      selector: (row) => row.based_on,
      sortable: true,
    },
    {
      name: 'Established At',
      selector: (row) => format(row.established_at, 'MM/dd/yyyy'),
      sortable: true,
    },
    {
      name: 'Phone Number',
      selector: (row) => row.phone_number,
      sortable: true,
    },
    {
      name: 'Actions',
      selector: (row) => row.actions,
      sortable: true,
      width: 'auto',
    },
  ];

  const filteredData = travelAgentData.filter(
    (row) =>
      row.name.toLowerCase().includes(searchTerm.toLowerCase()) 
  );

  const data = filteredData.map((row, index) => ({
    ...row,
    sno: index + 1,
    actions: generateActionButtons(row),
  }));

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs maintitle="Carvaan" title="Agents" breadcrumbItem="Agents Details" />

          <Row>
            <Col className="col-12">
              <Card style={{ minHeight: '80vh' }}>
                <CardBody style={{ minHeight: '80vh' }}>

                 <div className='d-flex justify-content-between'>
                 <CardTitle className="h4">Agents Details</CardTitle>
                  <div className="search-container">
                    <Input
                      type="text"
                      placeholder="Search "
                      className="search-input me-5"
                      value={searchTerm}
                      onChange={handleChange}
                    />
                  </div>
                 </div>

                  {loader && <Loader />}
                  {!loader && (
                    <div style={{ minHeight: '80vh' }}>
                      <DataTable columns={columns} data={data} pagination responsive />
                    </div>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

export default TravelAgentsDetails;
