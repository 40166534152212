// CreateCustomerForm.js
import React, { useEffect } from 'react';
import { Row, Col, Card, CardBody, CardTitle ,Container , Form, 
CardImg} from 'reactstrap';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import {  useDispatch, useSelector } from 'react-redux';
import {  useParams } from 'react-router-dom';
import {  getAllCustomPackages,  } from 'helpers/fakebackend_helper';
import {  setCustomerData } from 'store/auth/user_admin_data/actions';
import { format } from "date-fns";

const ViewCustomerRequest = () => {
  const { id } = useParams();
  const dispatch = useDispatch();

 


  const fetchOptions=async()=>{
    try {
     const customPackages= await getAllCustomPackages();

    dispatch(setCustomerData(customPackages));

    } catch (error) {
      console.log(error.response)
    }
  }
  useEffect(() => {
  fetchOptions()
  }, [id])

  const { customerData } = useSelector((state) => state.CustomerReducers);



  const customPackage = customerData.find((cPackage) => cPackage.id == id);


  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs link="/customerDetails" maintitle="Carvaan" title="Custom Package Request" breadcrumbItem={`View Request`} />

          <Row>
            <Col className="col-12">
              <Card >
                <CardBody>
                  <CardTitle className="h4">{`View`} Request</CardTitle>
                  
                  <div data-repeater-list="group-a">
                    <div data-repeater-item className="row w-100">


 

<div className="mb-3 col-lg-3">
  <label className="form-label" htmlFor="user_name">
    User Name
  </label>
  <Card body className="border">
    <CardTitle className="h4">{customPackage?.user_name}</CardTitle>
  </Card>
</div>

                


                      <div className="mb-3 col-lg-3">
                        <label className="  form-label" htmlFor="from">
                        User Email
                        </label>
                        <Card body className="border">
                        <CardTitle className="h4">{customPackage?.user_email}</CardTitle>
  </Card>

                      </div>

                      <div className="mb-3 col-lg-3">
                        <label className=" form-label" htmlFor="to">
                          Phone Number
                        </label>
                        <Card body className="border">
                        <CardTitle className="h4">{customPackage?.phone_number}</CardTitle>
  </Card>

                      </div>

                       <div className="mb-3 col-lg-3">
                        <label className=" form-label" htmlFor="destination">
                          Destination
                        </label>
                        <Card body className="border">
                        <CardTitle className="h4">{(customPackage?.destination)}</CardTitle>
                        </Card>

                      </div>

                      <div className="mb-3 col-lg-3">
                        <label className="form-label" htmlFor="duration_days">
                          Duration Days
                        </label>
                        <Card body className="border">
                        <CardTitle className="h4">{customPackage?.duration_days}</CardTitle>
  </Card>

                      </div>


                      <div className="mb-3 col-lg-3">
                        <label className="form-label" htmlFor="departure_date">
                          Departure Date
                        </label>
                        <Card body className="border">
                        <CardTitle className="h4">
  {customPackage?.departure_date && format(new Date(customPackage.departure_date), "MM/dd/yyyy")}
</CardTitle>  </Card>

                      </div>





                      <div className="mb-3 col-lg-3">
                        <label className="form-label" htmlFor="adult_seats">
                         Adult Seats Required
                        </label>
                        <Card body className="border">
                        <CardTitle className="h4">{customPackage?.adult_seats_required}</CardTitle>
  </Card>

                      </div>



                     

                      <div className="mb-3 col-lg-3">
                        <label className=" form-label" htmlFor="child_seats">
                          Child Seats Required
                        </label>
                        <Card body className="border">
                        <CardTitle className="h4">{customPackage?.child_seats_required}</CardTitle>
  </Card>

                      </div>

                     

                      

<div className="mb-3 col-lg-12">
  <label className=" form-label" htmlFor="message">
    Message
  </label>{" "}
  <Card body className="border">
  <CardTitle className="h4">{customPackage?.message}</CardTitle>
  </Card>

</div>





       







                   

<div className="mb-3 col-lg-3">
  <label className="form-label" htmlFor="hotel_ratings">
    Hotel Ratings
  </label>
  <Card body className="border">
    {customPackage?.hotel_ratings.map((rating, index) => (
      <div key={index} className='d-flex align-items-center'>
        <CardTitle className="h4 mb-0">{index + 1}</CardTitle>
        <CardBody className='fs-6'>
  {rating}
</CardBody>      </div>
    ))}
  </Card>
</div>


                 
                      <div className="mb-3 col-lg-3">
  <label className="form-label" htmlFor="meal_types">
    Meal Types
  </label>
  <Card body className="border">
    {customPackage?.meal_types.map((meal, index) => (
      <div key={index} className='d-flex align-items-center' >
        <CardTitle className="h4 ms-2 ">{index+1}</CardTitle>
        <CardTitle className="h4 ms-2 ">{meal}</CardTitle>
      </div>
    ))}
  </Card>
</div>





                    </div>


                    
                 
                  </div>

                 
                </CardBody>
              </Card>
            </Col>
          </Row>
         
        </div>
      </div>
    </React.Fragment>
  );
};

export default ViewCustomerRequest;
