import React from 'react'
//Import Breadcrumb

import EditAdminDetailForm from './EditAdminDetailsForm';
const EditAdminDetail = () => {

    return (
        <React.Fragment>
      <EditAdminDetailForm type="Edit"/>
    </React.Fragment>
    )
}

export default EditAdminDetail;