import React, { useEffect, useState } from 'react';
import { Row, Col, Card, CardBody, CardTitle, Label, Input, Form, FormFeedback } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { pushCustomer, setCustomerData, updateCustomer } from 'store/auth/user_admin_data/actions';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import { getAllCustomPackages, patchCustomPackagesRequestEdit } from 'helpers/fakebackend_helper';
import Alert from 'components/alert/Alert';

const CreateCustomerForm = ({ type }) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const navigate = useNavigate();

const [showError, setShowError] = useState(false)
const [message, setMessage] = useState("some Things Went's Wrong")

  let { customerData } = useSelector((state) => state.CustomerReducers);
  const customPackage = customerData.find((customer) => customer.id == id);
  


const fetchData=async()=>{
  try {
    let res=await getAllCustomPackages();
    
    console.log(res)
    
      dispatch(setCustomerData(res))
      if (customPackage) {
        validation.setFieldValue("user_name", customPackage?.user_name || '');
        validation.setFieldValue("user_email", customPackage?.user_email || '');
        validation.setFieldValue("phone_number", customPackage?.phone_number || '');
        validation.setFieldValue("destination", customPackage?.destination || '');
        validation.setFieldValue("departure_date", new Date(customPackage?.departure_date).toISOString().split('T')[0] || '');
        validation.setFieldValue("duration_days", customPackage?.duration_days || '');
        validation.setFieldValue("adult_seats_required", customPackage?.adult_seats_required || '');
        validation.setFieldValue("child_seats_required", customPackage?.child_seats_required || '');
        validation.setFieldValue("message", customPackage?.message || '');
        validation.setFieldValue("hotel_ratings", customPackage?.hotel_ratings || '');
        validation.setFieldValue("meal_types", customPackage?.meal_types || '');
      }

  } catch (error) {
    console.log("something wrongs")

  }
}

useEffect(() => {

  fetchData();

  
}

, [id])


  // const [customPackageEdit, setCustomPackageEdit] = useState(
  //  });

  const validation = useFormik({
    enableReinitialize: true,
    initialValues:  {
      user_name: '',
      user_email: '',
      phone_number: '',
      destination: '',
      departure_date: '',
      duration_days: '',
      adult_seats_required: '',
      child_seats_required: '',
      message: '',
      hotel_ratings: [],
      meal_types: [],
    }
    ,
    validationSchema: Yup.object({
      user_name: Yup.string().required('Please Enter User Name'),
      user_email: Yup.string().required('Please Enter Email'),
      phone_number: Yup.string().required('Please Enter Phone Number'),
      destination: Yup.string().required('Please Enter Destination'),
      departure_date: Yup.string().required('Please Enter Departure Date'),
      duration_days: Yup.string().required('Please Enter Duration Days'),
      adult_seats_required: Yup.number().required('Please Enter This Field'),
      child_seats_required: Yup.number().required('Please Enter This Field'),
      hotel_ratings: Yup.array().min(1, 'Please select at least one hotel rating'),
      meal_types: Yup.array().min(1, 'Please select at least one meal type'),
    }),
    onSubmit: async (values) => {
      // Handle form submission logic here
      console.log(values);
      if (type === 'Edit') {
        let res = await patchCustomPackagesRequestEdit(values,id)
        console.log(res)
        if(res.id){

          navigate('/customerDetails')
        }
        else{
          showError(true)

        }
        // Update logic
      } else {
        // Create logic
      }
    },
  });

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs link="customerDetails" maintitle="Carvaan" title="Customers Request" breadcrumbItem={`${type} Request`} />
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <CardTitle className="h4">{`${type}`} Request</CardTitle>
                  <Form onSubmit={validation.handleSubmit}>
                    <div data-repeater-list="group-a">
                      <div data-repeater-item className="row w-100">


                        <div className="mb-3 col-lg-3">
                          <Label className="form-label" htmlFor="user_name">
                            User Name
                          </Label>
                          <Input
                            type="text"
                            id="user_name"
                            required
                            className="form-control"
                            placeholder="Enter name"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.user_name}
                            invalid={validation.touched.user_name && !!validation.errors.user_name}
                          />
                          <FormFeedback type="invalid">{validation.errors.user_name}</FormFeedback>
                        </div>
                        <div className="mb-3 col-lg-3">
                          <Label className="form-label" htmlFor="user_email">
                            User Email
                          </Label>
                          <Input
                            type="email"
                            id="user_email"
                            required
                            className="form-control"
                            placeholder="Enter Email"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.user_email}
                            invalid={validation.touched.user_email && !!validation.errors.user_email}
                          />
                          <FormFeedback type="invalid">{validation.errors.user_email}</FormFeedback>
                        </div>
                        <div className="mb-3 col-lg-3">
                          <Label className="form-label" htmlFor="phone_number">
                            Phone Number
                          </Label>
                          <Input
                            type="number"
                            id="phone_number"
                            required
                            className="form-control"
                            placeholder="Enter Phone Number"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.phone_number}
                            invalid={validation.touched.phone_number && !!validation.errors.phone_number}
                          />
                          <FormFeedback type="invalid">{validation.errors.phone_number}</FormFeedback>
                        </div>
                        <div className="mb-3 col-lg-3">
                          <Label className="form-label" htmlFor="destination">
                            Destination
                          </Label>
                          <Input
                            type="text"
                            id="destination"
                            className="form-control"
                            required
                            placeholder="Enter Destination"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.destination}
                            invalid={validation.touched.destination && !!validation.errors.destination}
                          />
                          <FormFeedback type="invalid">{validation.errors.destination}</FormFeedback>
                        </div>
                        <div className="mb-3 col-lg-3">
                          <Label className="form-label" htmlFor="departure_date">
                          Departure Date
                          </Label>
                          <Input
                            type="date"
                            id="departure_date"
                            className="form-control"
                            required
                            placeholder="Enter Departure Date"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.departure_date}
                            invalid={validation.touched.departure_date && !!validation.errors.departure_date}
                          />
                          <FormFeedback type="invalid">{validation.errors.departure_date}</FormFeedback>
                        </div>
                        <div className="mb-3 col-lg-3">
                          <Label className="form-label" htmlFor="duration_days">
                          Duration Day
                          </Label>
                          <Input
                            type="number"
                            id="duration_days"
                            className="form-control"
                            placeholder="Enter Duration "
                            required
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.duration_days}
                            invalid={validation.touched.duration_days && !!validation.errors.duration_days}
                          />
                          <FormFeedback type="invalid">{validation.errors.duration_days}</FormFeedback>
                        </div>
                        <div className="mb-3 col-lg-3">
                          <Label className="form-label" htmlFor="adult_seats_required">
                          Adult Seats
                          </Label>
                          <Input
                            type="number"
                            id="adult_seats_required"
                            name='adult_seats_required'
                            className="form-control"
                            placeholder="Enter Adult Seats "
                            required
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.adult_seats_required}
                            invalid={validation.touched.adult_seats_required && !!validation.errors.adult_seats_required}
                          />
                          <FormFeedback type="invalid">{validation.errors.adult_seats_required}</FormFeedback>
                        </div>
                        <div className="mb-3 col-lg-3">
                          <Label className="form-label" htmlFor="child_seats_required">
                          Child Seats
                          </Label>
                          <Input
                            type="number"
                            id="child_seats_required"
                            className="form-control"
                            placeholder="Enter Child Seats "
                            name='child_seats_required'
                            required
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.child_seats_required}
                            invalid={validation.touched.child_seats_required && !!validation.errors.child_seats_required}
                          />
                          <FormFeedback type="invalid">{validation.errors.child_seats_required}</FormFeedback>
                        </div>
                        <div className="mb-3 col-lg-12">
                          <Label className="form-label" htmlFor="message">
                          Message
                          </Label>
                          <Input
                            type="textarea"
                            id="message"

                            rows={4}
                            className="form-control"
                            placeholder="Enter Message "
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.message}
                          />
                        </div>
<div className="mb-3 col-lg-12">
  <Label className="form-label">Hotel Ratings</Label>

  <div className="d-flex">
    <Label className="form-label " htmlFor="rating3">
      3-Rating
    </Label>
    <Input
      type="checkbox"
      id="rating3"
      name="hotel_ratings"
      value="3-Rating"
      className="ms-2"
      onChange={validation.handleChange}
      onBlur={validation.handleBlur}
      required={validation.values.hotel_ratings.length<1}

      checked={validation.values.hotel_ratings.includes("3-Rating")}
    />

    <Label className="form-label ms-3" htmlFor="rating4">
      4-Rating
    </Label>
    <Input
      type="checkbox"
      id="rating4"
      name="hotel_ratings"
      value="4-Rating"
      className="ms-2"
      onChange={validation.handleChange}
      required={validation.values.hotel_ratings.length<1}

      onBlur={validation.handleBlur}
      checked={validation.values.hotel_ratings.includes("4-Rating")}
    />

    <Label className="form-label ms-3" htmlFor="rating5">
      5-Rating
    </Label>
    <Input
      type="checkbox"
      id="rating5"
      name="hotel_ratings"
      value="5-Rating"
      className="ms-2"
      onChange={validation.handleChange}
      required={validation.values.hotel_ratings.length<1}

      onBlur={validation.handleBlur}
      checked={validation.values.hotel_ratings.includes("5-Rating")}
      
    />
  </div>
</div>
<div className="mb-3 col-lg-12">
  <Label className="form-label">Meal Type</Label>

  <div className="d-flex">
    <Label className="form-label " htmlFor="no_meals">
    No Meals
    
    </Label>
    <Input
      type="checkbox"
      id="no_meals"
      name="meal_types"
      value="No Meals"
      className="ms-2"
      onChange={validation.handleChange}
      onBlur={validation.handleBlur}
      checked={validation.values.meal_types.includes("No Meals")}
      required={validation.values.meal_types.length<1}

    />

<Label className="form-label ms-3 " htmlFor="all_meals">
  All Meals
    
    </Label>
    <Input
      type="checkbox"
      id="breakfast"
      name="meal_types"
      value="All Meals"
      className="ms-2"
      onChange={validation.handleChange}
      onBlur={validation.handleBlur}
      checked={validation.values.meal_types.includes("All Meals")}
      required={validation.values.meal_types.length<1}

    />


<Label className="form-label ms-3 " htmlFor="only_breakfast">
  Only Breakfast
    
    </Label>
    <Input
      type="checkbox"
      id="only_breakfast"
      name="meal_types"
      value="Only Breakfast"
      className="ms-2"
      onChange={validation.handleChange}
      onBlur={validation.handleBlur}
      checked={validation.values.meal_types.includes("Only Breakfast")}
      required={validation.values.meal_types.length<1}

    />


<Label className="form-label ms-3 " htmlFor="breakfast">
  Breakfast Lunch or Dinner
    
    </Label>
    <Input
      type="checkbox"
      id="breakfast"
      name="meal_types"
      value="Breakfast Lunch or Dinner"
      className="ms-2"
      onChange={validation.handleChange}
      onBlur={validation.handleBlur}
      checked={validation.values.meal_types.includes("Breakfast Lunch or Dinner")}
      required={validation.values.meal_types.length<1}

    />

  </div>


 
  
  </div>
  </div>

  
</div>





                   
                    <button type="submit" className="mt-1 btn btn-success">
                      {type === 'Edit' ? 'Update Request' : 'Create Customer'}
                    </button>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
      {
  true &&  <div className=" position-fixed  " style={{top:"50px",right:"0",zIndex:"9999"}}>
  <Alert message={message} type="error" />
</div>
 }
    </React.Fragment>
  );
};

export default CreateCustomerForm;
