import React, { useEffect, useState } from 'react';
import { FormFeedback, Row, Col, Card, CardBody, CardTitle, Form, Label, Input, Badge } from 'reactstrap';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import FsLightbox from "fslightbox-react";
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { SomethingAlertFalse, SomethingAlertTrue } from 'store/components/actions';
import Alert from 'components/alert/Alert';
import { createAgentPackage, getOneAgents, patchAgentsEdit, uploadTripImages } from 'helpers/fakebackend_helper';
import Loader from 'components/loader/Loader';
import StarRating from "../../components/StarRating"

const CreateTravelForm = ({ type }) => {
  const isOpen = useSelector(state => state.alertReducer.isOpen);
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const todayDate = new Date().toISOString().split('T')[0];
  const navigate = useNavigate();
  const [tripCreate, setTripCreate] = useState([]);
  const [message, setMessage] = useState("Something went's wrong")
  const { id } = useParams();
  const [foundTrip, setFoundTrip] = useState()
  const [rating, setRating] = useState(0);
  const [reviewArray, setReviewArray] = useState([]);
  const [newReview, setNewReview] = useState('');

  useEffect(() => {
    const fetchData = async () => {

      try {
        if (type === "Edit") {
          let tripData = await getOneAgents(id);
          setFoundTrip(tripData)
          validation.setFieldValue("company_name", tripData?.name);
          validation.setFieldValue("established_at", new Date(tripData?.established_at).toISOString().split('T')[0]);
          setSelectedProfile(tripData?.profile_picture)
          setIsImageSelected(true)
          validation.setFieldValue("phone_number", tripData?.phone_number)
          validation.setFieldValue("description", tripData?.description);
          validation.setFieldValue("based_on", tripData?.based_on);
          setRating(tripData?.rating || 0);
          setReviewArray(tripData?.review || []);
        }


      } catch (error) {
        console.error("Error fetching data:", error);
        setLoader(false);
        setMessage(error.response?.data.message ? error.response.data.message : "Something went wrong"
        )
        dispatch(SomethingAlertTrue());
        setTimeout(() => {
          dispatch(SomethingAlertFalse());
          setMessage("Something went wrong");
        }, 2000);
      }
    };

    fetchData(); // Call the fetchData function
  }, [id]);


  const fetchOptions = async () => {
    try {
    } catch (error) {
      setLoader(false);
      setMessage(error.response.data.message ? error.response.data.message : "Something went wrong"
      )
      dispatch(SomethingAlertTrue());
      setTimeout(() => {
        dispatch(SomethingAlertFalse());
        setMessage("Something went wrong");
      }, 2000);
    }
  }

  useEffect(() => {
    fetchOptions()
  }, [])


  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      company_name: tripCreate.company_name || "",
      established_at: tripCreate.established_at || "",
      phone_number: tripCreate.phone_number || "",
      description: tripCreate.description || "",
      based_on: tripCreate.based_on || "",

    },
    validationSchema: Yup.object({
      company_name: Yup.string().required("Please Enter The Company Name"),
      established_at: Yup.date().required("Please Enter Established At"),
      based_on: Yup.string().required("Please Enter Based On"),

      phone_number: Yup.number().required("Please Enter Phone Number"),
      description: Yup.string().required("Please Enter Description "),
    }),
    onSubmit: async (values) => {



      const userId = JSON.parse(localStorage.getItem("authUser")).admin.id;



      const tripData = {
        name: values.company_name,
        description: values.description,
        //   is_active: values.is_active,
        established_at: values.established_at,
        phone_number: values.phone_number.toString(),
        profile_picture: selectedProfile,
        based_on: values.based_on,
        rating: rating,
        review: reviewArray,
      };

      // Add created_by field if type is not Edit
      if (type !== "Edit") {
        tripData.created_by = userId;
      }

      // Add updated_by field if type is Edit
      if (type === "Edit") {
        tripData.updated_by = userId;
      }

      console.log(tripData)
      try {
        if (type === "Edit") {
          let res = await patchAgentsEdit(tripData, id)
          if (res.id) {
            navigate('/travelAgentDetails')
          }
        }
        else {
          let res = await createAgentPackage(tripData)
          if (res) {
            navigate('/travelAgentDetails')
          }
        }

      } catch (error) {
        setLoader(false);
        setMessage(error.response?.data?.message ? error.response?.data?.message : "Something went wrong"
        )
        dispatch(SomethingAlertTrue());
        setTimeout(() => {
          dispatch(SomethingAlertFalse());
          setMessage("Something went wrong");
        }, 2000);
      }

    }

  })
  const handleAddReview = () => {
    if (newReview.trim()) {
      setReviewArray([...reviewArray, newReview]);
      setNewReview(''); // Clear the input field after adding
    }
  };

  // Function to handle removing a review
  const handleDeleteReview = (index) => {
    const updatedReviews = reviewArray.filter((_, i) => i !== index);
    setReviewArray(updatedReviews);
  };
  const [isImageSelected, setIsImageSelected] = useState(false)
  const [selectedProfile, setSelectedProfile] = useState()

  const handleFileChange = async (e) => {
    setLoader(true)
    const file = e.target.files[0];
    const formData = new FormData();

    formData.append("files", file);


    try {
      const res = await uploadTripImages(formData);
      console.log(res[0]?.image_url)
      setIsImageSelected(true)
      setSelectedProfile(res[0]?.image_url)
      setLoader(false)

    } catch (error) {
      console.log(error)
      setLoader(false);
      setMessage(error?.response?.data?.message ? error?.response?.data?.message : error?.response?.statusText
      )
      dispatch(SomethingAlertTrue());
      setTimeout(() => {
        dispatch(SomethingAlertFalse());
        setMessage("Something went wrong");
      }, 2000);
      console.error("Error uploading image:", error);
    }
  };

  const handleProfileDelete = () => {
    setSelectedProfile(null);
    setIsImageSelected(false)
  };

  const [lightboxController, setLightboxController] = useState({
    toggler: false,
    slide: 1
  });

  function openLightboxOnSlide(number) {
    setLightboxController({
      toggler: !lightboxController.toggler,
      slide: number
    });
  }
  let { travelAgentData } = useSelector((state) => state.TravelAgentReducers);
  const trip = travelAgentData.find((trip) => trip.id == id);


  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs maintitle="Carvaan" title="Travel Agents" breadcrumbItem={`${type} Travel Agents `} />

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <CardTitle className="h4">{`${type}`} Travel Agents</CardTitle>
                  <Form
                    onSubmit={(e) => {
                      e.preventDefault();
                      validation.handleSubmit();
                      return false;
                    }}
                    action="#">

                    <div data-repeater-list="group-a">
                      <div data-repeater-item className="row w-100">


                        {/* Images */}
                        {loader && <Loader />}

                        {
                          !loader && <div className="mb-3 col-lg-12">
                            <label className="form-label" htmlFor="tripBanner">
                              Profile Picture*
                            </label>{" "}
                            <div className="mb-5">

                              <Input
                                type="file"
                                onChange={handleFileChange}
                                required={selectedProfile ? false : true}
                              />
                              <div className="dropzone-previews mt-3" id="file-previews">
                                <Row className="align-items-center ">
                                  {isImageSelected && <> <Card className="mt-1 col-1 mx-3 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
                                    <div className="py-2 d-flex align-items-center">
                                      <Col className="col-3">
                                        <img
                                          data-dz-thumbnail=""
                                          height="80"
                                          className="avatar-sm rounded bg-light"
                                          src={selectedProfile} // Access selectedBanner directly instead of selectedBanner[index]
                                          onClick={() => openLightboxOnSlide(1)} // Open lightbox with the clicked image's index
                                        />
                                      </Col>
                                      <Col className='ms-5 pe-2 col-3'>
                                        <i onClick={() => handleProfileDelete()} className='fas fa-trash-alt text-danger' role="button"></i>
                                      </Col>
                                    </div>
                                  </Card>
                                  </>}

                                  <FsLightbox
                                    toggler={lightboxController.toggler}
                                    sources={[selectedProfile]}
                                    types={['image']}
                                    slide={lightboxController.slide}
                                  />

                                </Row>
                              </div>
                            </div>
                          </div>
                        }
                        <label className="form-label" htmlFor="tripBanner">
                          Profile Picture*
                        </label>{" "}

                        <div className="mb-3 col-lg-4">
                          <Label className="form-label" htmlFor="company_name">
                            Travel Agents Name*
                          </Label>
                          <Input
                            type="text"
                            id="company_name"
                            className="form-control"
                            name="company_name"
                            required
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.company_name || ''}
                            invalid={validation.touched.company_name && validation.errors.company_name ? true : false}
                          >
                            {/* <option value="">Select a company</option>
        {travelAgents.map((option, index) => (
          <option key={index} value={parseInt(option.id)}>
            {option.name}
          </option>
        ))} */}
                          </Input>
                          {validation.touched.company_name && validation.errors.company_name ? (
                            <FormFeedback type="invalid">{validation.errors.company_name}</FormFeedback>
                          ) : null}
                        </div>

                        <div className="mb-3 col-lg-4">
                          <Label className="form-label" htmlFor="phone_number">
                            Phone Number*
                          </Label>
                          <Input
                            type="number"
                            id="phone_number"
                            className="form-control"
                            required
                            name='phone_number'
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.phone_number || ''}
                            invalid={validation.touched.phone_number && validation.errors.phone_number ? true : false}

                          />
                          {validation.touched.phone_number && validation.errors.phone_number ? (
                            <FormFeedback type="invalid">{validation.errors.phone_number}</FormFeedback>
                          ) : null}
                        </div>

                        <div className="mb-3 col-lg-12">
                          <Label className="form-label" htmlFor="description">
                            Description*
                          </Label>
                          <Input
                            type="textarea"
                            id="description"
                            name='description'
                            className="form-control"
                            placeholder="Enter Description "
                            required
                            rows={4}
                            style={{ resize: 'none' }}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.description || ''}
                            invalid={validation.touched.description && validation.errors.description ? true : false}

                          />{validation.touched.description && validation.errors.description ? (
                            <FormFeedback type="invalid">{validation.errors.description}</FormFeedback>
                          ) : null}
                        </div>

                        <div className="mb-3 col-lg-4">
                          <Label className="form-label" htmlFor="based_on">
                            Based On
                          </Label>
                          <Input
                            type="text"
                            id="based_on"
                            name='based_on'
                            required
                            className="form-control"
                            placeholder="Enter Based Location  "
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.based_on || ''}
                            invalid={validation.touched.based_on && validation.errors.based_on ? true : false}
                          />
                          {validation.touched.based_on && validation.errors.based_on ? (
                            <FormFeedback type="invalid">{validation.errors.based_on}</FormFeedback>
                          ) : null}
                        </div>
                        <div className="mb-3 col-lg-12">
                          <Label className="form-label" htmlFor="rating">
                            Rating
                          </Label>
                          <StarRating rating={rating} setRating={setRating} />
                        </div>

                        <div className='d-flex'>
                            <div className="mb-3 col-lg-4">
                              <Label className="form-label" htmlFor="review_ids">
                                Reviews
                              </Label>
                              <div className='d-flex justify-content-between'>
                                <Input
                                  type="text"
                                  id="review_ids"
                                  name="review_ids"
                                  className="form-control"
                                  placeholder="Enter a Review"
                                  onChange={(e) => setNewReview(e.target.value)}
                                  value={newReview}
                                  rows={4}
                                  style={{ resize: 'none' }}
                                />
                                <button onClick={handleAddReview} type='button' className='btn btn-success mx-1'>Add</button>
                              </div>
                              <div className='d-flex flex-column'>
                                <div className='d-flex flex-column'>
                                  {reviewArray.map((data, i) => (
                                    <div className='d-flex justify-content-between mx-3' key={i}>
                                      <h6 className='my-2 w-100 d-flex align-items-start justify-content-between'>
                                        {data}
                                        <Badge className='mx-2 bg-transparent'>
                                          <i onClick={() => handleDeleteReview(i)} role="button" className="fas fa-window-close fs-5 text-danger" />
                                        </Badge>
                                      </h6>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            </div>
                          <div className="mb-3 col-lg-4">
                            <Label className="form-label" htmlFor="established_at">
                              Established At*
                            </Label>
                            <Input
                              type="date"
                              id="established_at"
                              name='established_at'
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.established_at || ''}
                              invalid={validation.touched.established_at && validation.errors.established_at ? true : false}
                              className="form-control"
                              required
                              max={todayDate} // Ensure users cannot select a date before today
                            />
                            {validation.touched.established_at && validation.errors.established_at ? (
                              <FormFeedback type="invalid">{validation.errors.established_at}</FormFeedback>
                            ) : null}
                          </div>

                        </div>



                        <button type='submit' className="mt-1 btn btn-success">
                          {type === 'Edit' ? 'Update Agent' : 'Create Agent'}
                        </button>
                      </div>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
      <div className=" position-fixed  " style={{ top: "0", right: "0", zIndex: "9999" }}>
        {isOpen && <Alert message={message} type="error" />}
      </div>
    </React.Fragment>
  );
};

export default CreateTravelForm;
