import React from 'react'

import CreateCustomerForm from './CreateCustomerForm';

const CreateCustomer = () => {

    return (
        <React.Fragment>
      <CreateCustomerForm type="Create"/>
    </React.Fragment>
    )
}

export default CreateCustomer