import React, { useState } from 'react';

const StarRating = ({ rating, setRating }) => {
  const handleRating = (rate) => {
    setRating(rate);
    console.log("Rate",rate)
  };

  return (
    <div className="star-rating">
      {[1, 2, 3, 4, 5].map((star) => (
        <i
          key={star}
          className={`fas fa-star ${star <= rating ? 'text-warning' : 'text-muted'}`}
          onClick={() => handleRating(star)}
          style={{ cursor: 'pointer', fontSize: '24px', marginRight: '5px' }}
        ></i>
      ))}
    </div>
  );
};

export default StarRating;
