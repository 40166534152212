import React, { useEffect, useState } from 'react';
import { Row, Col, Input, Card, CardBody, CardTitle } from 'reactstrap';
import { format } from 'date-fns';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setCustomerData, popCustomer } from '../../store/auth/user_admin_data/actions';
import { deleteCustomPackagesRequest, getAllCustomPackages } from '../../helpers/fakebackend_helper';
import Loader from '../../components/loader/Loader';
import DataTable from 'react-data-table-component';
import Swal from 'sweetalert2';
import { SomethingAlertFalse, SomethingAlertTrue } from '../../store/components/actions';

const CustomerDetails = () => {
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const [message, setMessage] = useState("Something went wrong");

  const fetchData = async () => {
    try {
      let customData = await getAllCustomPackages();
      dispatch(setCustomerData(customData));
      setFilteredData(customData);
      setLoader(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoader(false);
    }
  };

  useEffect(() => {
    setLoader(true);
    fetchData();
  }, []);

  const handleRemoveCustomer = async (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!"
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          let res = await deleteCustomPackagesRequest(id);
          dispatch(popCustomer(id)); // Update Redux state after successful deletion
          setFilteredData(filteredData.filter(customer => customer.id !== id)); // Update filteredData state
          console.log(res);
        } catch (error) {
          setMessage(
            error.response.data.message ? error.response.data.message : "Something went wrong"
          );
          dispatch(SomethingAlertTrue());
          setTimeout(() => {
            dispatch(SomethingAlertFalse());
            setMessage("Something went wrong");
          }, 2000);
        }
      } else {
        // Handle cancel action
      }
    });
  };

  const generateActionButtons = (row) => (
    <div>
      <Link to={`/customPackage/${row.id}`}>
        <button className="btn btn-primary mx-2">
          <i className="ti-eye"></i>
        </button>
      </Link>
      <button className="btn btn-danger mx-2" onClick={() => handleRemoveCustomer(row.id)}>
        <i className="ti-trash"></i>
      </button>
      <Link to={`/editCustomer/${row.id}`}>
        <button className="btn btn-info mx-2">
          <i className="fas fa-edit"></i>
        </button>
      </Link>
    </div>
  );

  const { customerData } = useSelector((state) => state.CustomerReducers);

  const columns = [
    {
      name: 'User Name',
      selector: (row) => row.user_name,
      sortable: true,
    },
    {
      name: 'User Email',
      selector: (row) => row.user_email,
      sortable: true,
    },
    {
      name: 'Destination',
      selector: (row) => row.destination,
      sortable: true,
    },
    {
      name: 'Departure Date',
      selector: (row) => format(row.departure_date, 'MM/dd/yyyy'),
      sortable: true,
    },
    {
      name: 'Actions',
      cell: (row) => generateActionButtons(row),
      sortable: false,
      width: 'auto',
    },
  ];

  const handleChange = (e) => {
    const searchTerm = e.target.value.toLowerCase();
    setSearchTerm(searchTerm);
    const filtered = customerData.filter(
      (row) =>
        row.user_name.toLowerCase().includes(searchTerm) ||
        row.user_email.toLowerCase().includes(searchTerm) ||
        row.destination.toLowerCase().includes(searchTerm)
    );
    setFilteredData(filtered);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs
            link="customerDetails"
            maintitle="Carvaan"
            title="Custom Package"
            breadcrumbItem="Custom Package Request"
          />

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <div className='d-flex justify-content-between align-items-center mb-3'>
                    <CardTitle className="h4 mb-0">Custom Package Request</CardTitle>
                    <div className="search-container">
                      <Input
                        type="text"
                        placeholder="Search"
                        className="search-input"
                        value={searchTerm}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  {loader ? (
                    <Loader />
                  ) : (
                    <div style={{ minHeight: '80vh' }}>
                      <DataTable columns={columns} data={filteredData} pagination responsive />
                    </div>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

export default CustomerDetails;
