import React from 'react'
import { Row, Col, Card, CardBody, CardTitle,Form } from "reactstrap";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import CreateExistTripForm from '../tripExisting/CreateExistTripForm';

const CreateExistTrip = () => {

    return (
        <React.Fragment>
      <CreateExistTripForm type="Create"/>
    </React.Fragment>
    )
}

export default CreateExistTrip