


// CreateCustomerForm.js
import React, { useEffect, useState } from 'react';
import {
  Row, Col, Card, CardBody, CardTitle, Container, Form,
  CardImg
} from 'reactstrap';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Link } from "react-router-dom";
import { getAllAdmins, getAllAgeRange, getAllMeals, getAllPoints, getAllThemes, getAllTransportationTypes, getOneAgents, getPackage, get_All_Travel_Agents } from 'helpers/fakebackend_helper';
import { setAdminData, setTripData, storeAge, storeAgents, storeMeals, storePoints, storeTheme, storeTransportation } from 'store/auth/user_admin_data/actions';
import FsLightbox from "fslightbox-react";

const ViewTravelAgents = () => {
  const { id } = useParams();
  const dispatch = useDispatch();


  const [agentData, setAgentData] = useState()

  const [loader, setLoader] = useState(true);
  const [message, setMessage] = useState("Something went wrong");
  const [tempTrip, setTempTrip] = useState([])

  const fetchOptions = async () => {
    try {

      const agentData = await getOneAgents(id);
      setAgentData(agentData)
      let adminData = await getAllAdmins();
      dispatch(setAdminData(adminData));


    } catch (error) {
      console.log(error.response)
    }
  }
  useEffect(() => {
    fetchOptions()
  }, [id])

  const { adminData } = useSelector((state) => state.AdminReducers);




  const foundAdmin = adminData.find((admin) => admin.id === agentData?.created_by);
  const foundAdmin2 = adminData.find((admin) => admin.id === agentData?.updated_by);




  const [lightboxController, setLightboxController] = useState({
    toggler: false,
    slide: 1
  });


  function openLightboxOnSlide(number) {
    setLightboxController({
      toggler: !lightboxController.toggler,
      slide: number
    });
  }




  const [selectedBanner, setSelectedBanner] = useState(null);


  const formatDate = (dateString) => {
    if (!dateString) return 'N/A'; // or any placeholder you prefer
    const date = new Date(dateString);
    return isNaN(date.getTime()) ? 'Invalid date' : date.toISOString().split('T')[0];
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs link="/" maintitle="Carvaan" title="Agents" breadcrumbItem={`View Agents`} />

          <Row>
            <Col className="col-12">
              <Card >
                <CardBody>
                  <CardTitle className="h4">{`View`} Agent</CardTitle>

                  <div data-repeater-list="group-a">
                    <div data-repeater-item className="row w-100">


                      <div className="mb-3 col-lg-12">
                        <label className="form-label" htmlFor="tripBanner">
                          Profile Pictures
                        </label>{" "}
                        <div className="mb-5">
                          <Form>
                            <div className="d-flex dropzone-previews mt-3" id="file-previews">
                              {/* {trip && trip.images.map((image, index) => ( */}
                              <Card className="mt-1  d-flex justify-content-center align-items-center col-lg-3 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
                                <div className="p-2 d-flex">
                                  <Row className=" d-flex align-items-center justify-content-center">
                                    <Col className="col-auto d-flex align-items-center justify-content-center">
                                      <img
                                        style={{ height: "200px", width: "250px" }}
                                        data-dz-thumbnail=""
                                        className="avatar-sm rounded bg-light object-fit-cover"
                                        alt={`Banner`}
                                        src={agentData?.profile_picture}
                                        onClick={() => openLightboxOnSlide(1)}
                                      />
                                    </Col>
                                  </Row>
                                </div>
                              </Card>
                              {/* )} */}
                            </div>
                          </Form>
                        </div>
                      </div>

                      <div className="mb-3 col-lg-3">
                        <label className="form-label" htmlFor="agent_name">
                          Agent Name
                        </label>
                        <Card body className="border">
                          <CardTitle className="h4">{agentData?.name}</CardTitle>
                        </Card>
                      </div>

                      <div className="mb-3 col-lg-3">
                        <label className=" form-label" htmlFor="established_at">
                          Established At
                        </label>
                        <Card body className="border">
                          <CardTitle className="h4">{formatDate(agentData?.established_at)}</CardTitle>
                        </Card>

                      </div>

                      {/* <div className="mb-3 col-lg-3">
                        <label className="form-label" htmlFor="created_by">
                          Created By
                        </label>
                        <Card body className="border">
                          <CardTitle className="h4">{foundAdmin?.name}</CardTitle>
                        </Card>

                      </div> */}

                      {/* <div className="mb-3 col-lg-3">
                        <label className="form-label" htmlFor="updated_by">
                          Updated By
                        </label>
                        <Card body className="border">
                          <CardTitle className="h4">{foundAdmin2?.name}</CardTitle>
                        </Card>

                      </div> */}

                      <div className="mb-3 col-lg-3">
                        <label className="form-label" htmlFor="phone_number">
                          Phone Number
                        </label>
                        <Card body className="border">
                          <CardTitle className="h4">{agentData?.phone_number}</CardTitle>
                        </Card>
                      </div>

                      <div className="mb-3 col-lg-3">
                        <label className="form-label" htmlFor="based_on">
                          Based on
                        </label>
                        <Card body className="border">
                          <CardTitle className="h4">{agentData?.based_on}</CardTitle>
                        </Card>
                      </div>

                      {/* <div className="mb-3 col-lg-3">
                        <label className=" form-label" htmlFor="created_at">
                          Created At
                        </label>
                        <Card body className="border">
                          <CardTitle className="h4">{formatDate(agentData?.created_at)}</CardTitle>
                        </Card>
                      </div> */}
                      {/* <div className="mb-3 col-lg-3">
                        <label className=" form-label" htmlFor="updated_at">
                          Updated At
                        </label>
                        <Card body className="border">
                          <CardTitle className="h4">{formatDate(agentData?.updated_at)}</CardTitle>
                        </Card>
                      </div> */}
                      <div className="mb-3 col-lg-3">
                        <label className=" form-label" htmlFor="updated_at">
                          Trips Done
                        </label>
                        <Card body className="border">
                          <CardTitle className="h4">{agentData?.trips_done}</CardTitle>
                        </Card>
                      </div>
                      <div className="mb-3 col-lg-3">
                        <label className=" form-label" htmlFor="updated_at">
                        Rating
                        </label>
                        <Card body className="border">
                          <CardTitle className="h4">{agentData?.rating}</CardTitle>
                        </Card>
                      </div>
                      <div className="mb-3 col-lg-12">
                        <label className="form-label" htmlFor="day_wise_itinerary">
                          Reviews
                        </label>
                        <Card body className="border">
                          {agentData?.review.map((review, index) => (
                            <div key={index} className='d-flex align-items-center'>
                              <CardTitle className="h4 mb-0">Review {index + 1}</CardTitle>
                              <CardBody className='fs-6'>
                                {review}
                              </CardBody>
                            </div>
                          ))}
                        </Card>
                      </div>
                      {/* <div className="mb-3 col-lg-3">
                        <label className="form-label" htmlFor="transportation">
                          Created At
                        </label>
                        <Card body className="border">
                          <CardTitle className="h4">{formatDate(agentData?.created_at)}</CardTitle>
                        </Card>
                      </div> */}

                      <div className="mb-3 col-lg-12">
                        <label className="form-label" htmlFor="description">
                          Description
                        </label>
                        <Card body className="border">
                          <CardTitle className="h4">{agentData?.description}</CardTitle>
                        </Card>
                      </div>

                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <FsLightbox
            toggler={lightboxController.toggler}
            sources={[agentData?.profile_picture]}
            types={['image']}
            slide={lightboxController.slide}
          />

        </div>
      </div>
    </React.Fragment>
  );
};

export default ViewTravelAgents;
