import React, { useEffect, useState } from 'react';
import { Row, Col, Card, CardBody, CardTitle } from 'reactstrap';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import { useParams } from 'react-router-dom';
import { getAllContactUs } from 'helpers/fakebackend_helper';
import { format } from "date-fns";

const ContactUsView = () => {
  const { id } = useParams();
  const [contactData, setContactData] = useState({});

  const fetchOptions = async () => {
    try {
      let customData = await getAllContactUs();
      console.log(customData); // Check if data is fetched correctly
  
      // Check if customData is empty or null
      if (!customData || customData.length === 0) {
        setContactData({});
        return;
      }
  
      // Find the item with matching ID
      const foundItem = customData.find(item => String(item.id) === id);
      setContactData(foundItem || {}); // Set found item or empty object
    } catch (error) {
      console.log(error.response);
      // Handle error: setContactData({}) or show an error message
    }
  };
  

  useEffect(() => {
    fetchOptions();
  }, [id]);

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs link="/contactDetails" maintitle="Carvaan" title="Contact Us Request" breadcrumbItem={`View Request`} />
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <CardTitle className="h4">View Request</CardTitle>
                  <div data-repeater-list="group-a">
                    <div data-repeater-item className="row w-100">
                      <div className="mb-3 col-lg-3">
                        <label className="form-label" htmlFor="user_name">User Name</label>
                        <Card body className="border">
                          <CardTitle className="h4">{contactData?.name}</CardTitle>
                        </Card>
                      </div>
                      <div className="mb-3 col-lg-3">
                        <label className="form-label" htmlFor="user_email">User Email</label>
                        <Card body className="border">
                          <CardTitle className="h4">{contactData?.email}</CardTitle>
                        </Card>
                      </div>
                      <div className="mb-3 col-lg-3">
                        <label className="form-label" htmlFor="phone_number">Phone Number</label>
                        <Card body className="border">
                          <CardTitle className="h4">{contactData?.phone_number}</CardTitle>
                        </Card>
                      </div>
                      <div className="mb-3 col-lg-3">
                        <label className="form-label" htmlFor="message">Message</label>
                        <Card body className="border">
                          <CardTitle className="h4">{contactData?.message}</CardTitle>
                        </Card>
                      </div>
                      <div className="mb-3 col-lg-3">
                        <label className="form-label" htmlFor="requested_date">Requested Date</label>
                        <Card body className="border">
                          <CardTitle className="h4">{contactData?.created_at && format(new Date(contactData.created_at), "MM/dd/yyyy")}</CardTitle>
                        </Card>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ContactUsView;
